/* size */
:root {
    --height-navbar: 4rem;
    --height-bottombar: 5.625rem;
    --height-orderbook-viz: 65vh;
    --width-content: 1180px;
    --widgth-scrollbar: 8px;
}

/* rgb */
:root {
    --rgb-white: 255 255 255;
    --rgb-black: 0 0 0;
    /* gold */
    --rgb-gold99: 255 247 237;
    --rgb-gold95: 255 235 210;
    --rgb-gold90: 255 220 176;
    --rgb-gold80: 255 199 128;
    --rgb-gold70: 248 179 90;
    --rgb-gold60: 225 155 69;
    --rgb-gold50: 191 133 59;
    --rgb-gold40: 147 105 52;
    --rgb-gold30: 103 75 37;
    --rgb-gold20: 71 52 25;
    --rgb-gold10: 50 38 22;
    /* secondary */
    --rgb-secondary99: 251 246 242;
    --rgb-secondary95: 241 228 213;
    --rgb-secondary90: 232 210 187;
    --rgb-secondary80: 223 193 160;
    --rgb-secondary70: 197 170 140;
    --rgb-secondary60: 170 147 121;
    --rgb-secondary50: 146 124 99;
    --rgb-secondary40: 119 101 81;
    --rgb-secondary30: 91 77 62;
    --rgb-secondary20: 64 54 43;
    --rgb-secondary10: 42 36 30;
    /* purple */
    --rgb-purple99: 255 247 255;
    --rgb-purple95: 255 235 255;
    --rgb-purple90: 255 220 255;
    --rgb-purple80: 255 199 255;
    --rgb-purple70: 248 179 255;
    --rgb-purple60: 225 155 255;
    --rgb-purple50: 191 133 255;
    --rgb-purple40: 147 105 255;
    --rgb-purple30: 103 75 255;
    --rgb-purple20: 71 52 255;
    --rgb-purple10: 50 38 255;
    /* neutral */
    --rgb-neutral99: 250 250 250;
    --rgb-neutral95: 236 236 236;
    --rgb-neutral90: 216 214 214;
    --rgb-neutral80: 192 191 190;
    --rgb-neutral70: 169 167 166;
    --rgb-neutral60: 141 138 137;
    --rgb-neutral50: 117 114 113;
    --rgb-neutral40: 94 90 88;
    --rgb-neutral30: 71 66 64;
    --rgb-neutral20: 47 42 40;
    --rgb-neutral10: 24 18 16;
    /* neutral-variant */
    --rgb-neutral-variant99: 246 245 244;
    --rgb-neutral-variant95: 235 232 229;
    --rgb-neutral-variant90: 217 212 207;
    --rgb-neutral-variant80: 196 189 181;
    --rgb-neutral-variant70: 174 165 153;
    --rgb-neutral-variant60: 153 141 127;
    --rgb-neutral-variant50: 125 113 99;
    --rgb-neutral-variant40: 94 90 88;
    --rgb-neutral-variant30: 76 69 61;
    --rgb-neutral-variant20: 54 49 43;
    --rgb-neutral-variant10: 31 28 25;
    /* red */
    --rgb-red99: 253 242 241;
    --rgb-red95: 252 229 228;
    --rgb-red90: 249 207 205;
    --rgb-red80: 245 177 173;
    --rgb-red70: 240 134 127;
    --rgb-red60: 234 91 82;
    --rgb-red50: 228 48 37;
    --rgb-red40: 191 33 23;
    --rgb-red30: 145 25 18;
    --rgb-red20: 100 17 12;
    --rgb-red10: 63 11 8;
    /* green */
    --rgb-green99: 241 250 242;
    --rgb-green95: 220 241 222;
    --rgb-green90: 187 227 191;
    --rgb-green80: 143 210 150;
    --rgb-green70: 96 191 106;
    --rgb-green60: 68 168 78;
    --rgb-green50: 56 139 65;
    --rgb-green40: 44 110 51;
    --rgb-green30: 33 81 38;
    --rgb-green20: 24 59 27;
    --rgb-green10: 15 37 17;
    /* blue */
    --rgb-blue99: 239 243 255;
    --rgb-blue95: 218 229 255;
    --rgb-blue90: 193 210 255;
    --rgb-blue80: 162 188 255;
    --rgb-blue70: 132 166 255;
    --rgb-blue60: 96 140 255;
    --rgb-blue50: 50 107 255;
    --rgb-blue40: 4 74 255;
    --rgb-blue30: 0 52 188;
    --rgb-blue20: 0 38 137;
    --rgb-blue10: 0 25 91;
    /* orange */
    --rgb-orange99: 255 246 235;
    --rgb-orange95: 255 232 204;
    --rgb-orange90: 255 213 163;
    --rgb-orange80: 255 189 112;
    --rgb-orange70: 255 166 61;
    --rgb-orange60: 255 138 0;
    --rgb-orange50: 214 116 0;
    --rgb-orange40: 173 94 0;
    --rgb-orange30: 133 72 0;
    --rgb-orange20: 92 50 0;
    --rgb-orange10: 61 33 0;
    /* yellow */
    --rgb-yellow99: 255 250 236;
    --rgb-yellow95: 255 241 205;
    --rgb-yellow90: 255 227 154;
    --rgb-yellow80: 255 212 103;
    --rgb-yellow70: 255 195 42;
    --rgb-yellow60: 241 173 0;
    --rgb-yellow50: 205 147 0;
    --rgb-yellow40: 170 122 0;
    --rgb-yellow30: 134 96 0;
    --rgb-yellow20: 99 70 0;
    --rgb-yellow10: 62 45 0;
}

/* color */
:root {
    --color-white: rgb(var(--rgb-white));
    --color-white-o10: rgb(var(--rgb-white) / 0.10);
    --color-black: rgb(var(--rgb-black));
    --color-black-o24: rgb(var(--rgb-black) / 0.24);
    --color-black-o28: rgb(var(--rgb-black) / 0.28);
    --color-black-o10: rgb(var(--rgb-black) / 0.1);
    --color-black-o20: rgb(var(--rgb-black) / 0.2);
    --color-black-o30: rgb(var(--rgb-black) / 0.3);
    --color-black-o40: rgb(var(--rgb-black) / 0.4);
    --color-black-o50: rgb(var(--rgb-black) / 0.5);
    /* gold */
    --color-gold99: rgb(var(--rgb-gold99));
    --color-gold95: rgb(var(--rgb-gold95));
    --color-gold95-o24: rgb(var(--rgb-gold95) / 0.24);
    --color-gold95-o28: rgb(var(--rgb-gold95) / 0.28);
    --color-gold90: rgb(var(--rgb-gold90));
    --color-gold90-o24: rgb(var(--rgb-gold90) / 0.24);
    --color-gold90-o28: rgb(var(--rgb-gold90) / 0.28);
    --color-gold80: rgb(var(--rgb-gold80));
    --color-gold80-o0: rgb(var(--rgb-gold80) / 0);
    --color-gold80-o4: rgb(var(--rgb-gold80) / 0.04);
    --color-gold80-o5: rgb(var(--rgb-gold80) / 0.05);
    --color-gold80-o8: rgb(var(--rgb-gold80) / 0.08);
    --color-gold80-o11: rgb(var(--rgb-gold80) / 0.11);
    --color-gold80-o12: rgb(var(--rgb-gold80) / 0.12);
    --color-gold80-o14: rgb(var(--rgb-gold80) / 0.14);
    --color-gold80-o16: rgb(var(--rgb-gold80) / 0.16);
    --color-gold80-o24: rgb(var(--rgb-gold80) / 0.24);
    --color-gold80-o28: rgb(var(--rgb-gold80) / 0.28);
    --color-gold70: rgb(var(--rgb-gold70));
    --color-gold60: rgb(var(--rgb-gold60));
    --color-gold60-o24: rgb(var(--rgb-gold60) / 0.24);
    --color-gold60-o28: rgb(var(--rgb-gold60) / 0.28);
    --color-gold50: rgb(var(--rgb-gold50));
    --color-gold50-o25: rgb(var(--rgb-gold50) / 0.25);
    --color-gold50-o40: rgb(var(--rgb-gold50) / 0.4);
    --color-gold50-o50: rgb(var(--rgb-gold50) / 0.5);
    --color-gold40: rgb(var(--rgb-gold40));
    --color-gold40-o30: rgb(var(--rgb-gold40) / 0.3);
    --color-gold30: rgb(var(--rgb-gold30));
    --color-gold30-o24: rgb(var(--rgb-gold30) / 0.24);
    --color-gold30-o28: rgb(var(--rgb-gold30) / 0.28);
    --color-gold20: rgb(var(--rgb-gold20));
    --color-gold20-o24: rgb(var(--rgb-gold20) / 0.24);
    --color-gold20-o28: rgb(var(--rgb-gold20) / 0.28);
    --color-gold10: rgb(var(--rgb-gold10));
    --color-gold10-o24: rgb(var(--rgb-gold10) / 0.24);
    --color-gold10-o28: rgb(var(--rgb-gold10) / 0.28);
    /* secondary */
    --color-secondary99: rgb(var(--rgb-secondary99));
    --color-secondary95: rgb(var(--rgb-secondary95));
    --color-secondary95-o24: rgb(var(--rgb-secondary95) / 0.24);
    --color-secondary95-o28: rgb(var(--rgb-secondary95) / 0.28);
    --color-secondary90: rgb(var(--rgb-secondary90));
    --color-secondary90-o24: rgb(var(--rgb-secondary90) / 0.24);
    --color-secondary90-o28: rgb(var(--rgb-secondary90) / 0.28);
    --color-secondary80: rgb(var(--rgb-secondary80));
    --color-secondary80-o24: rgb(var(--rgb-secondary80) / 0.24);
    --color-secondary80-o28: rgb(var(--rgb-secondary80) / 0.28);
    --color-secondary70: rgb(var(--rgb-secondary70));
    --color-secondary60: rgb(var(--rgb-secondary60));
    --color-secondary50: rgb(var(--rgb-secondary50));
    --color-secondary40: rgb(var(--rgb-secondary40));
    --color-secondary30: rgb(var(--rgb-secondary30));
    --color-secondary30-o24: rgb(var(--rgb-secondary30) / 0.24);
    --color-secondary30-o28: rgb(var(--rgb-secondary30) / 0.28);
    --color-secondary20: rgb(var(--rgb-secondary20));
    --color-secondary20-o24: rgb(var(--rgb-secondary20) / 0.24);
    --color-secondary20-o28: rgb(var(--rgb-secondary20) / 0.28);
    --color-secondary10: rgb(var(--rgb-secondary10));
    /* purple */
    --color-purple99: rgb(var(--rgb-purple99));
    --color-purple95: rgb(var(--rgb-purple95));
    --color-purple95-o24: rgb(var(--rgb-purple95) / 0.24);
    --color-purple95-o28: rgb(var(--rgb-purple95) / 0.28);
    --color-purple90: rgb(var(--rgb-purple90));
    --color-purple90-o24: rgb(var(--rgb-purple90) / 0.24);
    --color-purple90-o28: rgb(var(--rgb-purple90) / 0.28);
    --color-purple80: rgb(var(--rgb-purple80));
    --color-purple80-o24: rgb(var(--rgb-purple80) / 0.24);
    --color-purple80-o28: rgb(var(--rgb-purple80) / 0.28);
    --color-purple70: rgb(var(--rgb-purple70));
    --color-purple60: rgb(var(--rgb-purple60));
    --color-purple50: rgb(var(--rgb-purple50));
    --color-purple40: rgb(var(--rgb-purple40));
    --color-purple30: rgb(var(--rgb-purple30));
    --color-purple30-o24: rgb(var(--rgb-purple30) / 0.24);
    --color-purple30-o28: rgb(var(--rgb-purple30) / 0.28);
    --color-purple20: rgb(var(--rgb-purple20));
    --color-purple20-o24: rgb(var(--rgb-purple20) / 0.24);
    --color-purple20-o28: rgb(var(--rgb-purple20) / 0.28);
    --color-purple10: rgb(var(--rgb-purple10));
    /* neutral */
    --color-neutral99: rgb(var(--rgb-neutral99));
    --color-neutral95: rgb(var(--rgb-neutral95));
    --color-neutral95-o24: rgb(var(--rgb-neutral95) / 0.24);
    --color-neutral95-o28: rgb(var(--rgb-neutral95) / 0.28);
    --color-neutral95-o40: rgb(var(--rgb-neutral95) / 0.4);
    --color-neutral90: rgb(var(--rgb-neutral90));
    --color-neutral90-o24: rgb(var(--rgb-neutral90) / 0.24);
    --color-neutral90-o28: rgb(var(--rgb-neutral90) / 0.28);
    --color-neutral80: rgb(var(--rgb-neutral80));
    --color-neutral70: rgb(var(--rgb-neutral70));
    --color-neutral60: rgb(var(--rgb-neutral60));
    --color-neutral60-o24: rgb(var(--rgb-neutral60) / 0.24);
    --color-neutral50: rgb(var(--rgb-neutral50));
    --color-neutral40: rgb(var(--rgb-neutral40));
    --color-neutral30: rgb(var(--rgb-neutral30));
    --color-neutral20: rgb(var(--rgb-neutral20));
    --color-neutral20-o24: rgb(var(--rgb-neutral20) / 0.24);
    --color-neutral20-o28: rgb(var(--rgb-neutral20) / 0.28);
    --color-neutral10: rgb(var(--rgb-neutral10));
    --color-neutral10-o24: rgb(var(--rgb-neutral10) / 0.24);
    --color-neutral10-o28: rgb(var(--rgb-neutral10) / 0.28);
    --color-neutral10-o80: rgb(var(--rgb-neutral10) / 0.8);
    --color-neutral10-o90: rgb(var(--rgb-neutral10) / 0.9);
    /* neutral-variant */
    --color-neutral-variant99: rgb(var(--rgb-neutral-variant99));
    --color-neutral-variant95: rgb(var(--rgb-neutral-variant95));
    --color-neutral-variant90: rgb(var(--rgb-neutral-variant90));
    --color-neutral-variant80: rgb(var(--rgb-neutral-variant80));
    --color-neutral-variant80-o24: rgb(var(--rgb-neutral-variant80) / 0.24);
    --color-neutral-variant80-o28: rgb(var(--rgb-neutral-variant80) / 0.28);
    --color-neutral-variant70: rgb(var(--rgb-neutral-variant70));
    --color-neutral-variant60: rgb(var(--rgb-neutral-variant60));
    --color-neutral-variant60-o24: rgb(var(--rgb-neutral-variant60) / 0.24);
    --color-neutral-variant60-o28: rgb(var(--rgb-neutral-variant60) / 0.28);
    --color-neutral-variant50: rgb(var(--rgb-neutral-variant50));
    --color-neutral-variant50-o24: rgb(var(--rgb-neutral-variant50) / 0.24);
    --color-neutral-variant50-o28: rgb(var(--rgb-neutral-variant50) / 0.28);
    --color-neutral-variant40: rgb(var(--rgb-neutral-variant40));
    --color-neutral-variant30: rgb(var(--rgb-neutral-variant30));
    --color-neutral-variant30-o24: rgb(var(--rgb-neutral-variant30) / 0.24);
    --color-neutral-variant30-o28: rgb(var(--rgb-neutral-variant30) / 0.28);
    --color-neutral-variant20: rgb(var(--rgb-neutral-variant20));
    --color-neutral-variant20-o24: rgb(var(--rgb-neutral-variant20) / 0.24);
    --color-neutral-variant20-o28: rgb(var(--rgb-neutral-variant20) / 0.28);
    --color-neutral-variant10: rgb(var(--rgb-neutral-variant10));
    /* red */
    --color-red99: #FDF2F1;
    --color-red95: #FCE5E4;
    --color-red95-o24: rgba(var(--rgb-red95) / 0.24);
    --color-red90: #F9CFCD;
    --color-red80: #F5B1AD;
    --color-red70: #F0867F;
    --color-red60: rgb(var(--rgb-red60));
    --color-red60-o24: rgba(var(--rgb-red60) / 0.24);
    --color-red50: #E43025;
    --color-red40: #BF2117;
    --color-red30: #911912;
    --color-red20: #64110C;
    --color-red10: #3F0B08;
    /* green */
    --color-green99: #F1FAF2;
    --color-green95: #DCF1DE;
    --color-green95-o24: rgba(var(--rgb-green95) / 0.24);
    --color-green90: #BBE3BF;
    --color-green80: #8FD296;
    --color-green70: #60BF6A;
    --color-green60: rgb(var(--rgb-green60));
    --color-green60-o24: rgba(var(--rgb-green60) / 0.24);
    --color-green50: #388B41;
    --color-green40: #2C6E33;
    --color-green30: #215126;
    --color-green20: #183B1B;
    --color-green10: #0F2511;
    /* blue */
    --color-blue99: #EFF3FF;
    --color-blue95: rgba(var(--rgb-blue95));
    --color-blue95-o24: rgba(var(--rgb-blue95) / 0.24);

    --color-blue90: #C1D2FF;
    --color-blue80: #A2BCFF;
    --color-blue70: #84A6FF;
    --color-blue60: rgb(var(--rgb-blue60));
    --color-blue60-o24: rgba(var(--rgb-blue60) / 0.24);
    --color-blue50: #326BFF;
    --color-blue40: #044AFF;
    --color-blue30: #0034BC;
    --color-blue20: #002689;
    --color-blue10: #00195B;
    /* orange */
    --color-orange99: #FFF6EB;
    --color-orange95: #FFE8CC;
    --color-orange90: #FFD5A3;
    --color-orange80: #FFBD70;
    --color-orange70: #FFA63D;
    --color-orange60: #FF8A00;
    --color-orange50: #D67400;
    --color-orange40: #AD5E00;
    --color-orange30: #854800;
    --color-orange20: #5C3200;
    --color-orange10: #3D2100;
    /* yellow */
    --color-yellow99: #FFFAEC;
    --color-yellow95: #FFF1CD;
    --color-yellow90: #FFE39A;
    --color-yellow80: #FFD467;
    --color-yellow70: #FFC32A;
    --color-yellow60: rgb(var(--rgb-yellow60));
    --color-yellow60-o24: rgba(var(--rgb-yellow60) / 0.24);
    --color-yellow50: #CD9300;
    --color-yellow40: #AA7A00;
    --color-yellow30: #866000;
    --color-yellow20: #624600;
    --color-yellow10: #3E2D00;
}

/* data viz colors; needed to inject raw css color to fill path in Chart component */
:root {
    --color-dataviz-categorical-1: var(--color-gold80);
    --color-dataviz-categorical-2: var(--color-purple60);
    --color-dataviz-categorical-3: var(--color-green80);
    --color-dataviz-categorical-4: var(--color-red70);
    --color-dataviz-categorical-5: var(--color-blue60);
    --color-dataviz-categorical-others: var(--color-neutral60);
}

:root {
    --color-primary: var(--color-gold80);
    --color-liquid: var(--color-blue60);
    --color-surface-variant: #4F483F;
    --color-surface: var(--color-neutral10);
    --color-tooltip_o24: var(--color-neutral90-o24);
    --color-tv: var(--color-neutral10);
}

/* glow */
:root {
    --glow-s: 0px 0px 10px rgba(191, 133, 59, 0.9);
    --glow-m: 0px 0px 20px var(--color-gold50-o40);
    --glow-l: 0px 0px 60px var(--color-gold40-o30);
    --glow-1: 0px 0px 10px 1px var(--color-gold50-o25), 0px 0px 4px var(--color-gold50-o50);
    --glow-2: 0px 0px 12px 2px var(--color-gold50-o25), 0px 0px 6px var(--color-gold50-o50);
    --glow-3: 0px 0px 16px 3px var(--color-gold50-o25), 0px 0px 8px var(--color-gold50-o50);
    --glow-4: 0px 0px 20px 6px var(--color-gold50-o25), 0px 0px 10px var(--color-gold50-o50);
    --glow-5: 0px 0px 24px 8px var(--color-gold50-o25), 0px 0px 16px var(--color-gold50-o50);
    --glow-text-1: 0px 0px 10px var(--color-gold50-o25), 0px 0px 4px var(--color-gold50-o50);
    --glow-text-2: 0px 0px 12px var(--color-gold50-o25), 0px 0px 6px var(--color-gold50-o50);
    --glow-text-3: 0px 0px 16px var(--color-gold50-o25), 0px 0px 8px var(--color-gold50-o50);
    --glow-text-4: 0px 0px 20px var(--color-gold50-o25), 0px 0px 10px var(--color-gold50-o50);
    --glow-text-5: 0px 0px 24px var(--color-gold50-o25), 0px 0px 16px var(--color-gold50-o50);
}
