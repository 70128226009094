/* v6.x ~ */
.tippy-box[data-theme~='custom'][data-placement^='top'] > .tippy-arrow::before {
  border-top-color: var(--color-tooltip_o24);
}

.tippy-box[data-theme~='custom'][data-placement^='bottom'] > .tippy-arrow::before {
  border-bottom-color: var(--color-tooltip_o24);
}

.tippy-box[data-theme~='custom'][data-placement^='left'] > .tippy-arrow::before {
  border-left-color: var(--color-tooltip_o24);
}

.tippy-box[data-theme~='custom'][data-placement^='right'] > .tippy-arrow::before {
  border-right-color: var(--color-tooltip_o24);
}

.dark .tippy-box[data-theme~='custom'][data-placement^='top'] > .tippy-arrow::before {
  border-top-color: var(--color-tooltip_o24);
  transform: translateY(1px);
}

.dark .tippy-box[data-theme~='custom'][data-placement^='bottom'] > .tippy-arrow::before {
  border-bottom-color: var(--color-tooltip_o24);
  transform: translateY(-1px);
}

.dark .tippy-box[data-theme~='custom'][data-placement^='left'] > .tippy-arrow::before {
  border-left-color: var(--color-tooltip_o24);
  transform: translateX(1px);
}

.dark .tippy-box[data-theme~='custom'][data-placement^='right'] > .tippy-arrow::before {
  border-right-color: var(--color-tooltip_o24);
  transform: translateX(-1px);
}

.tippy-content {
  padding: 0;
}